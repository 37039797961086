import React from "react";
import {Nav, Navbar} from "react-bootstrap";
import { withRouter } from "react-router";
import logo from '../../icons/logo.png';
import kulaLogo from '../../icons/kula-logo.svg';
import './NavigationSidebar.module.css'
import _ from 'lodash';

const Side = props => {
    const { activeLink, links, linksWithSubheaders, onClick, hideCollapseToggle, isKula = false} = props;
    return (
        <>
            <Navbar defaultExpanded expand="xs" style={{height: '100%',backgroundColor: isKula ? '#15723A' : '#f0f0f0'}} className={"d-block sidebar" + (isKula ? ' navbar-dark' : '')}>
                { !hideCollapseToggle && <Navbar.Toggle aria-controls="responsive-navbar-nav" /> }
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav
                        activeKey={activeLink}
                        variant="pills"
                    >
                        <div className="sidebar-sticky"></div>
                        <div className='link_style'>
                            <Nav.Item>
                                <Nav.Link href="/help">
                                    <img className='logo_style' src={isKula ? kulaLogo : logo} alt="Logo" height={isKula ? 90 : null}/>
                                </Nav.Link>
                            </Nav.Item>
                            {
                                !_.isEmpty(links) && links.map(e => 
                                    <Nav.Item>
                                        {
                                            <Nav.Link active={activeLink === e.link} href={_.isNil(onClick) ? e.link : '#'} onClick={_.isNil(onClick) ? null : () => onClick(e.link, e.label)}>{e.label}</Nav.Link>
                                        }
                                    </Nav.Item>
                                )
                            }
                        </div>
                        <div className='link_style'>
                            {
                                !_.isEmpty(linksWithSubheaders) && linksWithSubheaders.map(header => 
                                    <Nav.Item key={header.label}>
                                        <h5>{header.label}</h5>
                                        {
                                            !_.isEmpty(header.links) && header.links.map(headerLink => <Nav.Link active={activeLink === headerLink.link} key={headerLink.link} href={_.isNil(onClick) ? headerLink.link : '#'} onClick={_.isNil(onClick) ? null : () => onClick(headerLink.link, headerLink.label)}>{headerLink.label}</Nav.Link>) 
                                        }
                                    </Nav.Item>
                                )
                            }
                        </div>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>
    );
};

const NavigationSidebar = withRouter(Side);
export default NavigationSidebar;