import React from "react";
import { Spinner } from "react-bootstrap";

import clsx from "clsx";

import styles from "./ButtonV2.module.css";

const ButtonV2 = ({
    text,
    variant = "outline",
    onClick,
    isDisabled,
    type,
    customStyling,
    isSpinnerShown,
    style,
    paddingX,
    Icon
}) => {
    return (
        <button
            className={clsx("btn", customStyling, {
                [styles.buttonGreen]: variant === "fill",
                [styles.buttonWhite]: variant === "outline",
                "px-4": !paddingX,
                [`px-${paddingX}`]: paddingX,
            })}
            type={type || "button"}
            disabled={isDisabled}
            onClick={onClick}
            style={{
                ...style,
                cursor: isDisabled ? 'not-allowed' : 'pointer',
            }}
        >
            {isSpinnerShown && (
                <Spinner
                    className={'mr-1'}
                    animation="border"
                    variant={variant === "fill" ? "light" : "dark"}
                    size="sm"
                />
            )}{" "}
            <span>
              {(Icon && !isSpinnerShown) && <Icon className="mb-1 mr-1" />} {text}
            </span>
        </button>
    );
};

export default ButtonV2;
