import React, { useState } from "react";
import ButtonV2 from "../../../../components/ButtonV2/ButtonV2";
import Table from "react-bootstrap/Table";
import Select from "react-select";
import { Formik, Form } from "formik";
import * as manpowerApi from "../../../../apis/ManpowerDatabase";
import BulkUploadModal from "../Component/BulkUploadModal";
import ContentWithSideContent from "../Component/ContentWithSideContent";
import Pagination from "../../../../components/Pagination/Pagination";
import { Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import ConfirmationModal from "../Component/ConfirmationModal";
import MenuAuthCheck from "../../../../components/AuthCheck/MenuAuthCheck";
import { MANPOWER_VIEW, MANPOWER_WHITELIST, MANPOWER_WRITE_UPDATE } from "../../../../constants/scope";

const _ = require('lodash');

const PersonalSection = (props) => {
    const SIZE = 100;
    const BULK_UPDATE_PERSONAL_CSV = 'https://docs.google.com/spreadsheets/d/1P5hi25ebKDxw2IWmaG1-VUEHdhWYJv-ZS7U3cid-820/edit#gid=1203891362';

    const { options, displayAlert } = props;
    const [formData, setFormData] = useState({
        name: "",
        employeeIds: [],
    });
    const [responseData, setResponseData] = useState([]);
    const [user, setUser] = useState({
        userId: "",
        internalUserId: "",
        employeeId: "",
        nikKtp: "",
        name: "",
        address: "",
        phone: "",
        bankName: "",
        bankAccountName: "",
        bankAccount: "",
        gender: "",
        birthPlace: "",
        birthDate: null,
        bloodType: "",
        motherName: "",
        religion: "",
        maritalStatus: "",
        npwp: "",
        skck: "",
        bpjs: "",
        bpjstk: "",
        taxStatus: ""
    });

    const [tempUser, setTempUser] = useState();
    const [showUserDetail, setShowUserDetail] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [bulkUploadErrors, setBulkUploadErrors] = useState([]);
    const [pagination, setPagination] = useState({
        currentPage: 0,
        totalPages: 0,
        numberOfElements: _.max([responseData.length, SIZE]),
        totalElements: 0,
    });

    const [hideResult, setHideResult] = useState(
        {
            nikKtp: false,
            address: false,
            phone: false,
            bankName: false,
            bankAccount: false,
            bankAccountName: false,
            gender: false,
            birthDate: false
        }
    );

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const isKula = props.isKula ?? false;

    const InputGroup = (props) => {
        const { values, options, setFieldValue } = props;
        return (
            <div className={"d-flex"}>
                <div className="mr-3">
                    <label>Employee Id</label>
                    <Select
                        value={values.employeeIds}
                        options={options.employeeIdOptions}
                        onChange={(selected) => {
                            setFieldValue('employeeIds', selected);
                        }}
                        selected={values.employeeIds}
                        isClearable={true}
                        isMulti={true}
                    />
                </div>

                <div>
                    <label>Name</label>
                    <input
                        className="form-control"
                        value={values.name}
                        onChange={(value) => {
                           setFieldValue('name', value.target.value);
                        }}
                    />
                </div>
            </div>
        );
    }

    const ButtonGroup = (props) => {
        return (
            <div className={"d-flex mt-2"}>
                <MenuAuthCheck allowedScopes={[MANPOWER_VIEW]}>
                <ButtonV2 style={{ marginRight: "5px" }} text={"Search"} type={"submit"} />
                </MenuAuthCheck>
                <MenuAuthCheck allowedScopes={[MANPOWER_WRITE_UPDATE]}>
                <ButtonV2 text={"Bulk Changes"} onClick={() => { setShowModal(true) }} />
                </MenuAuthCheck>
            </div>
        );
    }

    const buildRequestData = (values) => {
        const requestData = {};
        const internalUserIds = [];
        values.employeeIds.forEach((user) => {
            internalUserIds.push(user.value);
        });
        
        if(internalUserIds.length > 0){
            requestData.internalUserIds = Array.from(internalUserIds).join(',');
        }

        requestData.name = values.name;

        return requestData;
    }

    const fetchUserData = async (values) => {
        setFormData(values);
        getPersonalData(1, values);
    }

    const getPersonalData = async (page, values) => {
        const actualPage = !_.isNil(pagination.totalPages) && pagination.totalPages != 0 ? (page - 1) % pagination.totalPages : (page - 1);
        const offsetPage = actualPage + 1;
        const requestData = buildRequestData(values);
        try {
            const response = await manpowerApi.fetchPersonalData({...requestData, size: SIZE, sort: "id,asc", page: actualPage, isFromKula: isKula });
            setResponseData(response.data.data.data);
            let newPagination = {
                currentPage: offsetPage,
                numberOfElements: _.max([responseData.length, SIZE]),
                totalElements: response.data.data.totalElements,
                totalPages: response.data.data.totalPages,
            };
            setPagination(newPagination);
        } catch (e) {
            displayAlert({ message: e.message, type: 'error' })
        }
    }

    const onChangeHideResult = (propertyName, e) => {
        let newHideResult = { ...hideResult };
        newHideResult[propertyName] = !newHideResult[propertyName];
        setHideResult(newHideResult);
    }

    const generateSideContent = (user) => {
        setUser(user);
        setTempUser(user);
        setShowUserDetail(true);
    }

    const TableResult = (props) => {
        return (
            <>
                <div className={"hide-collection mt-3 mb-2 border-top d-flex flex-column"}>
                    <div className={"row mb-1 mt-1"}>
                        <div className={"col form-check hover-cursor text-wrap"} onClick={(e) => { onChangeHideResult("nikKtp", e) }}>
                            <input className={"mr-1"} type='checkbox' checked={hideResult.nikKtp} onChange={(e) => { onChangeHideResult("nikKtp", e) }} />
                            Nik Ktp
                        </div>
                        <div className={"col form-check hover-cursor text-wrap"} onClick={(e) => { onChangeHideResult("address", e) }}>
                            <input className={"mr-1"} type='checkbox' checked={hideResult.address} onChange={(e) => { onChangeHideResult("address", e) }} />
                            Alamat
                        </div>
                        <div className={"col form-check hover-cursor text-wrap"} onClick={(e) => { onChangeHideResult("phone", e) }}>
                            <input className={"mr-1"} type='checkbox' checked={hideResult.phone} onChange={(e) => { onChangeHideResult("phone", e) }} />
                            No Telepon
                        </div>
                        <div className={"col form-check hover-cursor text-wrap"} onClick={(e) => { onChangeHideResult("bankName", e) }}>
                            <input className={"mr-1"} type='checkbox' checked={hideResult.bankName} onChange={(e) => { onChangeHideResult("bankName", e) }} />
                            Bank Name
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"col form-check hover-cursor text-wrap"} onClick={(e) => { onChangeHideResult("bankAccount", e) }}>
                            <input className={"mr-1"} type='checkbox' checked={hideResult.bankAccount} onChange={(e) => { onChangeHideResult("bankAccount", e) }} />
                            Bank Account
                        </div>
                        <div className={"col form-check hover-cursor text-wrap"} onClick={(e) => { onChangeHideResult("bankAccountName", e) }}>
                            <input className={"mr-1"} type='checkbox' checked={hideResult.bankAccountName} onChange={(e) => { onChangeHideResult("bankAccountName", e) }} />
                            Bank Account Name
                        </div>
                        <div className={"col form-check hover-cursor text-wrap"} onClick={(e) => { onChangeHideResult("gender", e) }}>
                            <input className={"mr-1"} type='checkbox' checked={hideResult.gender} onChange={(e) => { onChangeHideResult("gender", e) }} />
                            Gender
                        </div>
                        <div className={"col form-check hover-cursor text-wrap"} onClick={(e) => { onChangeHideResult("birthDate", e) }}>
                            <input className={"mr-1"} type='checkbox' checked={hideResult.birthDate} onChange={(e) => { onChangeHideResult("birthDate", e) }} />
                            Tanggal Lahir
                        </div>
                    </div>
                </div>

                <div className={""}>
                    <Pagination {...pagination} goToPage={(page) => getPersonalData(page)} />
                </div>

                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Nik Karyawan</th>
                            <th>Nama Lengkap</th>

                            {!hideResult.nikKtp ? <th>Nik KTP</th> : <></>}
                            {!hideResult.address ? <th>Alamat</th> : <></>}
                            {!hideResult.phone ? <th>No Hp</th> : <></>}
                            {!hideResult.bankName ? <th>Bank Name</th> : <></>}
                            {!hideResult.bankAccount ? <th>Bank Account</th> : <></>}
                            {!hideResult.bankAccountName ? <th>Account Name</th> : <></>}
                            {!hideResult.gender ? <th>Gender</th> : <></>}
                            {!hideResult.birthDate ? <th>Tempat Lahir</th> : <></>}

                            <th>Tanggal Lahir</th>
                            <th>Golongan Darah</th>
                            <th>Nama Ibu Kandung</th>
                            <th>Agama</th>
                            <th>Status Pernikahan</th>
                            <th>NPWP</th>
                            <th>SKCK</th>
                            <th>BPJS Kesehatan</th>
                            <th>BPJS Tenaga Kerja </th>
                            <th>Status Pajak</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {responseData && responseData.length > 0 ? buildResponse()
                            : <></>}
                    </tbody>
                </Table>
            </>
        );
    }

    const buildResponse = () => {
        return responseData.map(
            (data) => {
                return (
                    <tr key={data.userId}>

                        <td>{generateTableRowData(data, "employeeId")}</td>
                        <td>{generateTableRowData(data, "name")}</td>


                        {!hideResult.nikKtp ? <td>{generateTableRowData(data, "nikKtp")}</td> : <></>}
                        {!hideResult.address ? <td>{generateTableRowData(data, "address")}</td> : <></>}
                        {!hideResult.phone ? <td>{generateTableRowData(data, "phone")}</td> : <></>}
                        {!hideResult.bankName ? <td>{generateTableRowData(data, "bankName")}</td> : <></>}
                        {!hideResult.bankAccount ? <td>{generateTableRowData(data, "bankAccount")}</td> : <></>}
                        {!hideResult.bankAccountName ? <td>{generateTableRowData(data, "bankAccountName")}</td> : <></>}
                        {!hideResult.gender ? <td>{generateTableRowData(data, "gender")}</td> : <></>}
                        <td>{generateTableRowData(data, "birthPlace")}</td>
                        {!hideResult.birthDate ? <td>{generateTableRowData(data, "birthDate")}</td> : <></>}
                        <td>{generateTableRowData(data, "bloodType")}</td>
                        <td>{generateTableRowData(data, "motherName")}</td>
                        <td>{generateTableRowData(data, "religion")}</td>
                        <td>{generateTableRowData(data, "maritalStatus")}</td>
                        <td>{generateTableRowData(data, "npwp")}</td>
                        <td>{generateTableRowData(data, "skck")}</td>
                        <td>{generateTableRowData(data, "bpjs")}</td>
                        <td>{generateTableRowData(data, "bpjstk")}</td>
                        <td>{generateTableRowData(data, "taxStatus")}</td>
                        <td onClick={() => { generateSideContent(data) }}><a>Edit Data</a></td>
                    </tr>
                );
            }
        );
    }

    const generateTableRowData = (data, propertyName) => {
        let text = "";
        if (data && data[propertyName]) {
            text = data[propertyName];
        }
        return text;
    }

    const toggleBulkUploadModal = (state) => {
        const newState = state;
        setShowModal(newState);
        setBulkUploadErrors([]);
    }
    const generateMain = () => {
        return (
            <>
                <ConfirmationModal
                    show={showConfirmationModal}
                    toggleModal={setShowConfirmationModal}
                    submit={singleUserUpdate}
                    user={user}
                />
                <BulkUploadModal
                    title={"Bulk Update Personal Data"}
                    isShown={showModal}
                    toggleModal={toggleBulkUploadModal}
                    csvUrl={BULK_UPDATE_PERSONAL_CSV}
                    isSubmitting={isSubmitting}
                    setSubmitting={setIsSubmitting}
                    submit={submitUpdatePersonalData}
                    ErrorList={bulkUpdateErrors}
                    keepModal={true}
                />
                <div className={"overflow-auto h-100"}>
                    <Formik className={"d-flex"} initialValues={formData} onSubmit={fetchUserData}>
                        {(formikProps) =>
                            <Form>
                                <InputGroup {...formikProps} options={options} />
                                <ButtonGroup />
                            </Form>
                        }
                    </Formik>
                    <TableResult />
                </div>
            </>
        );
    }

    const submitUpdatePersonalData = async (formData) => {
        try {
            formData.append('isFromKula', isKula);
            const response = await manpowerApi.bulkUpdatePersonalData({ data: formData })
            const responseData = response.data.data;
            if (responseData.error === true) {
                setBulkUploadErrors(responseData.errors);
                displayAlert({ message: 'Silahkan Liat Tabel Untuk Detail', type: 'error' });
            } else {
                setShowModal(false);
                displayAlert({ message: 'Success', type: 'success' });
            }
        } catch (e) {
            displayAlert({ message: e.message, type: 'error' })
        }
    }

    const bulkUpdateErrors = () => {
        let keys = Object.keys(bulkUploadErrors);
        return (
            <>
                <Table bordered>
                    <thead>
                        <th>Row</th>
                        <th>Nik Karyawan</th>
                        <th>Nama Lengkap</th>
                        <th>Nik KTP</th>
                        <th>Alamat</th>
                        <th>No Hp</th>
                        <th>Bank Name</th>
                        <th>Bank Account</th>
                        <th>Bank Account Name</th>
                        <th>Gender</th>
                        <th>Tempat Lahir</th>
                        <th>Tanggal Lahir</th>
                        <th>Golongan Darah</th>
                        <th>Nama Ibu Kandung</th>
                        <th>Agama</th>
                        <th>Status Pernikahan</th>
                        <th>NPWP</th>
                        <th>SKCK</th>
                        <th>BPJS</th>
                        <th>BPJSTK</th>
                        <th>Pajak Status</th>
                    </thead>

                    <tbody>
                        {
                            keys.map((key) => {
                                let rowNumber = parseInt(key) + 1;
                                let error = bulkUploadErrors[key];
                                return (
                                    <tr>
                                        <td>{rowNumber}</td>
                                        <td>{generateTableRowData(error, 'employeeId')}</td>
                                        <td>{generateTableRowData(error, 'name')}</td>
                                        <td>{generateTableRowData(error, 'nikKtp')}</td>
                                        <td>{generateTableRowData(error, 'address')}</td>
                                        <td>{generateTableRowData(error, 'phone')}</td>
                                        <td>{generateTableRowData(error, 'bankName')}</td>
                                        <td>{generateTableRowData(error, 'bankAccount')}</td>
                                        <td>{generateTableRowData(error, 'bankAccountName')}</td>
                                        <td>{generateTableRowData(error, 'gender')}</td>
                                        <td>{generateTableRowData(error, 'birthPlace')}</td>
                                        <td>{generateTableRowData(error, 'birthDate')}</td>
                                        <td>{generateTableRowData(error, 'bloodType')}</td>
                                        <td>{generateTableRowData(error, 'motherName')}</td>
                                        <td>{generateTableRowData(error, 'religion')}</td>
                                        <td>{generateTableRowData(error, 'maritalStatus')}</td>
                                        <td>{generateTableRowData(error, 'npwp')}</td>
                                        <td>{generateTableRowData(error, 'skck')}</td>
                                        <td>{generateTableRowData(error, 'bpjs')}</td>
                                        <td>{generateTableRowData(error, 'bpjstk')}</td>
                                        <td>{generateTableRowData(error, 'taxStatus')}</td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </Table>
            </>
        );
    }

    const singleUserUpdate = async (values) => {
        let updateUserPromise = null;
        try {
            updateUserPromise = await manpowerApi.updatePersonalData(values);
            if (updateUserPromise.status === 200) {
                getPersonalData(pagination.currentPage, formData);
                displayAlert({ message: `Success`, type: "success" });
            }
        } catch (e) {
            displayAlert({ message: `${e.message}`, type: "error" });
        }
    }

    const userDetailForm = (props) => {
        const { values, setFieldValue } = props;
        return (
            <Form>
                <div className="">Edit Data</div>
                <Table>
                    <tbody>
                        <tr>
                            <td>Nik Karyawan</td>
                            <td>
                                <input className={"form-control"} value={values.employeeId} onChange={(value) => { setFieldValue("employeeId", value.target.value) }} readOnly={true} />
                            </td>
                        </tr>
                        <tr>
                            <td>Nama Lengkap</td>
                            <td>
                                <input className={"form-control"} value={values.name} onChange={(value) => { setFieldValue("name", value.target.value) }} />
                            </td>
                        </tr>
                        <tr>
                            <td>Nik KTP</td>
                            <td>
                                <input className={"form-control"} value={values.nikKtp} onChange={(value) => { setFieldValue("nikKtp", value.target.value) }} />
                            </td>
                        </tr>
                        <tr>
                            <td>Alamat</td>
                            <td>
                                <input className={"form-control"} value={values.address} onChange={(value) => { setFieldValue("address", value.target.value) }} />
                            </td>
                        </tr>
                        <tr>
                            <td>No Hp</td>
                            <td>
                                <input className={"form-control"} value={values.phone} onChange={(value) => { setFieldValue("phone", value.target.value) }} />
                            </td>
                        </tr>
                        <tr>
                            <td>Bank Name</td>
                            <td>
                                <input className={"form-control"} value={values.bankName} onChange={(value) => { setFieldValue("bankName", value.target.value) }} />
                            </td>
                        </tr>
                        <tr>
                            <td>Bank Account</td>
                            <td>
                                <input className={"form-control"} value={values.bankAccount} onChange={(value) => { setFieldValue("bankAccount", value.target.value) }} />
                            </td>
                        </tr>
                        <tr>
                            <td>Account Name</td>
                            <td>
                                <input className={"form-control"} value={values.bankAccountName} onChange={(value) => { setFieldValue("bankAccountName", value.target.value) }} />
                            </td>
                        </tr>
                        <tr>
                            <td>Gender</td>
                            <td>
                                <Select
                                    defaultValue={{ value: values.gender, label: values.gender }}
                                    onChange={(selected) => { setFieldValue("gender", selected.value) }}
                                    options={
                                        [
                                            { value: "MALE", label: "male" },
                                            { value: "FEMALE", label: "female" },
                                        ]
                                    }
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Tempat Lahir</td>
                            <td>
                                <input className={"form-control"} value={values.birthPlace} onChange={(value) => { setFieldValue("birthPlace", value.target.value) }} />
                            </td>
                        </tr>
                        <tr>
                            <td>Tanggal Lahir</td>
                            <td>
                                <DatePicker
                                    className={"form-control"}
                                    selected={values.birthDate ? new Date(values.birthDate) : null}
                                    onChange={(date) => { setFieldValue("birthDate", date) }}
                                    placeholderText='yyyy-mm-dd'
                                    dateFormat='yyyy-MM-dd' />
                            </td>
                        </tr>
                        <tr>
                            <td>Golongan Darah</td>
                            <td>
                                <Select
                                    defaultValue={{ value: values.bloodType, label: values.bloodType }}
                                    onChange={(selected) => { setFieldValue("bloodType", selected.value) }}
                                    options={
                                        [
                                            { value: "A", label: "A" },
                                            { value: "B", label: "B" },
                                            { value: "AB", label: "AB" },
                                            { value: "O", label: "O" }
                                        ]
                                    }
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Nama Ibu</td>
                            <td>
                                <input className={"form-control"} value={values.motherName} onChange={(value) => { setFieldValue("motherName", value.target.value) }} />
                            </td>
                        </tr>
                        <tr>
                            <td>Agama</td>
                            <td>
                                <Select
                                    defaultValue={{ value: values.religion, label: values.religion }}
                                    onChange={(selected) => { setFieldValue("religion", selected.value) }}
                                    options={
                                        [
                                            { value: "Islam", label: "Islam" },
                                            { value: "Kristen", label: "Kristen" },
                                            { value: "Katolik", label: "Katolik" },
                                            { value: "Buddha", label: "Buddha" },
                                            { value: "Hindu", label: "Hindu" },
                                            { value: "Konghucu", label: "Konghucu" }
                                        ]
                                    }
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Status Pernikahan</td>
                            <td>
                                <Select
                                    defaultValue={{ value: values.maritalStatus, label: values.maritalStatus }}
                                    onChange={(selected) => { setFieldValue("maritalStatus", selected.value) }}
                                    options={
                                        [
                                            { value: "Single", label: "Single" },
                                            { value: "Married", label: "Married" }
                                        ]
                                    }
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>NPWP</td>
                            <td>
                                <input className={"form-control"} value={values.npwp} onChange={(value) => { setFieldValue("npwp", value.target.value) }} />
                            </td>
                        </tr>
                        <tr>
                            <td>SKCK</td>
                            <td>
                                <input className={"form-control"} value={values.skck} onChange={(value) => { setFieldValue("skck", value.target.value) }} />
                            </td>
                        </tr>
                        <tr>
                            <td>BPJS</td>
                            <td>
                                <input className={"form-control"} value={values.bpjs} onChange={(value) => { setFieldValue("bpjs", value.target.value) }} />
                            </td>
                        </tr>
                        <tr>
                            <td>BPJSTK</td>
                            <td>
                                <input className={"form-control"} value={values.bpjstk} onChange={(value) => { setFieldValue("bpjstk", value.target.value) }} />
                            </td>
                        </tr>
                        <tr>
                            <td>Status Pajak</td>
                            <td>
                                <Select
                                    defaultValue={{ value: values.taxStatus, label: values.taxStatus }}
                                    onChange={(selected) => { setFieldValue("taxStatus", selected.value) }}
                                    options={
                                        [
                                            { value: "active", label: "Aktif" },
                                            { value: "inactive", label: "Inaktif" }
                                        ]
                                    }
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={"2"}>
                                <div className={"d-flex"}>
                                    <button className={"btn btn-primary"} type={"button"} onClick={() => { setUser(values); setShowConfirmationModal(true) }}>
                                        Simpan
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Form>
        );
    }

    const generateSide = () => {
        return (
            <Formik className={"d-flex"} initialValues={user} enableReinitialize={true}>
                {(formikProps) =>
                    userDetailForm({ ...formikProps })
                }
            </Formik>
        );
    }

    return (
        <ContentWithSideContent Main={generateMain()} Side={generateSide()} show={showUserDetail} hideSideContent={setShowUserDetail} />
    );
}


export default PersonalSection;