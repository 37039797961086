import { MANPOWER_DATABASE, MANPOWER_DATABASE_EMPLOYMENT, MANPOWER_DATABASE_PERSONAL } from "../constants/api";
import customAxios from "./axios";
import * as qs from "query-string";

export const fetchEmploymentData = (params) => {
    return customAxios.get(MANPOWER_DATABASE_EMPLOYMENT, {
        params: params, paramsSerializer: params => {
            return qs.stringify(params, {
                arrayFormat: 'comma',
            })
        }
    }
    );
}

export const fetchPersonalData = (params) => {
    return customAxios.get(MANPOWER_DATABASE_PERSONAL, { params: params });
}

export const updatePersonalData = (data) => {
    return customAxios.put(MANPOWER_DATABASE_PERSONAL, data);
}

export const bulkUpdatePersonalData = ({ data }) => {
    return customAxios.put(`${MANPOWER_DATABASE_PERSONAL}/bulk`, data);
}

export const bulkUpdateEmploymentData = ({ data }) => {
    return customAxios.put(`${MANPOWER_DATABASE_EMPLOYMENT}/bulk`, data);
}

export const bulkCreateManpowerUser = ({ data }) => {
    return customAxios.post(`${MANPOWER_DATABASE_EMPLOYMENT}/bulk`, data);
}

export const updateEmploymentData = (data) => {
    return customAxios.put(`${MANPOWER_DATABASE_EMPLOYMENT}`, data);
}

export const generatePayroll = ({monthOfPayroll, effectiveDate, workplaceDepartmentId, isIncludeMissingData, isFromKula}) => {
    return customAxios.post(
        `${MANPOWER_DATABASE}/generate-payroll`, {
            monthOfPayroll,
            effectiveDate,
            workplaceDepartmentId,
            isIncludeMissingData,
            isFromKula,
        }
    )
}

export const getDivisionOptions = () => {
    return customAxios.get(
        `${MANPOWER_DATABASE}/division-options`
    )
}